const Progress = (props) => {
    const progress = props.progress;
   switch (progress) {
       case 'pending':
           return <span className="text-warning h5 fw-bolder">Pending</span>;
     case 'open':
           return <span className="text-primary h5 fw-bolder">open</span>;
     case 'closed':
           return <span className="text-success h5 fw-bolder">closed</span>;
     case 'unresolved':
           return <span className="text-danger h5 fw-bolder">unresolved</span>;    
   }    
}

export default Progress
