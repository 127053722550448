import { useEffect } from "react";
import Mandatory from "../components/layout/Mandatory";
import { showalert } from "../components/Helpers/Utils";

const CreateRequest = () => {
  useEffect(() => {
    document.getElementById("expected_completion_date").min = new Date()
      .toISOString()
      .split("T")[0];
  }, []);

  useEffect(() => {
    const frmRequest = document.getElementById("frmRequest");
    frmRequest.addEventListener("submit", (e) => {
      const formData = new FormData(frmRequest);
      formData.append("client_contact_id", localStorage.getItem("contact_id"));
      formData.append("contact_email", localStorage.getItem("contact_email"));

      createTicket(formData)
        .then((data) => {
          showalert(data, "success");
          createEmail(formData);
        })
        .catch((error) => {
          console.log(error);
        });
      e.preventDefault();
    });
  }, []);

  const createTicket = async (formDt) => {
    const url = "https://www.innovations.co.ke/portalapi/public/api/createRequests";
    const options = {
      method: "POST",
      body: formDt,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error("There was an error creating ticket :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  function createEmail(formData) {
    emailCreate(formData)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const emailCreate = async (formDt) => {
    const url = "https://www.innovations.co.ke/portalapi/public/api/createMail";
    const options = {
      method: "POST",
      body: formDt,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error("There was an error creating email :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  function countCharacters(val) {
    document.getElementById("charCount").innerHTML =
      "Characters left : " + (2000 - val.length);
  }

  return (
    <div className="card col-md-12 mx-auto" style={{ marginTop: "5%" }}>
      <div className="card-header text-center bg-white">
        <h6 style={{ float: "left", color: "red" }}>
          Fields marked with (<Mandatory />) are Mandatory!
        </h6>
      </div>
      <div className="card-body">
        <form
          id="frmRequest"
          className="d-grid gap-3"
          encType="multipart/form-data"
        >
          <div className="form-group">
            <label htmlFor="email" className="text-info fw-bolder">
              Subject
              <Mandatory />
            </label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              id="subject"
              name="subject"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="priority" className="text-info fw-bolder">
              Priority
              <Mandatory />
            </label>
            <select className="form-select" name="priority" required>
              <option value="">Select Priority</option>
              <option value="1">low</option>
              <option value="2">medium</option>
              <option value="3">High</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="fileToUpload" className="text-info fw-bolder">
              Image (choose an image with max size of 2mb)
            </label>
            <input
              type="file"
              className="form-control"
              autoComplete="off"
              id="fileToUpload"
              name="fileToUpload[]"
              multiple="multiple"
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="otherFileToUpload" className="text-info fw-bolder">
              File (choose a file of type doc, excel, pdf, or csv )
            </label>
            <input
              type="file"
              className="form-control"
              autoComplete="off"
              id="otherFileToUpload"
              name="otherFileToUpload[]"
              multiple="multiple"
              accept="text/html, text/plain, .pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="expected_completion_date"
              className="text-info fw-bolder"
            >
              Expected Completion Date
              <Mandatory />
            </label>
            <input
              type="date"
              name="expected_completion_date"
              id="expected_completion_date"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="text-info fw-bolder">
              Description (Please enter a detailed description of the ticket)
              <Mandatory />
            </label>
            <span
              className="text-success"
              style={{ float: "right" }}
              id="charCount"
            >
              Characters left : 2000
            </span>
            <textarea
              type="text"
              rows="10"
              className="form-control"
              autoComplete="off"
              id="request_description"
              name="request_description"
              minLength="5"
              maxLength="2000"
              onKeyUp={(e) => countCharacters(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            id="submit"
            className="btn btn-info text-white fw-bolder mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateRequest;
