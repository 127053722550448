import { Route, Switch } from "react-router-dom";

import CreateRequest from "../../pages/CreateRequest";
import ViewRequests from "../../pages/ViewRequests";
import RequestDetails from "../../pages/RequestDetails";
import CreateComment from "../../pages/CreateComment"
import TicketSummary from "../../pages/TicketSummary"
import CommentList from "../../pages/CommentList"
import MainNavigation from "./MainNavigation";
import PassChange from "../../pages/PassChange";

const PortalBody = (props) => {
  return (
    <div>
      <MainNavigation imgUrl={props.imgUrl} alt={props.alt} />
      <div id="content">
        <Switch>
          <Route path="/" exact>
            <CreateRequest />
          </Route>
          <Route path="/view-ticket">
            <ViewRequests />
          </Route>
          <Route path="/ticket-details">
            <RequestDetails />
          </Route>
          <Route path="/create-comment">
            <CreateComment />
          </Route>
          <Route path="/ticket-summary">
            <TicketSummary />
          </Route>
          <Route path="/comment-list">
            <CommentList />
          </Route>
          <Route path="/pass-change">
            <PassChange />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default PortalBody;
