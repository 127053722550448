import TimeRemaining from "../components/Helpers/TimeRemaining";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Progress from "../components/layout/Progress";

const RequestTable = (props) => {
  let history = useHistory();
  const client_id = localStorage.getItem("client_id");
  const [loadedRequests, setLoadedRequests] = useState([]);

  function getDetails(event) {
    let rowValues = [];
    let child = event.target.parentElement.parentElement.children;
    for (let x of child) {
      rowValues.push(x.textContent);
    }
    history.push({
      pathname: "/ticket-details",
      state: {
        response: rowValues,
      },
    });
  }

  useEffect(() => {
      requests()
        .then((data) => {
          data.map((dt) => {
            setLoadedRequests(dt.name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  const requests = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/viewOneDeveloper/" + client_id
    );
    if (response.status !== 200) {
      throw new Error("There was an error fetching tickets :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return (
    <div className="table-responsive" style={{ marginTop: "5%" }}>
      <table className="table table-bordered table-hover">
        <thead className="table-secondary text-info">
          <tr>
            <th style={{ display: "none" }}>Request Id</th>
            <th>Developer Name</th>
            <th>Subject</th>
            <th>Priority</th>
            <th>Creation Date</th>
            <th>Expected Completion Date</th>
            <th>Time Remaining</th>
            <th>Ticket Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.loadedRequests.map((dt) => {
            return (
              <tr>
                <td style={{ display: "none" }}> {dt.request_id} </td>
                <td> {loadedRequests} </td>
                <td> {dt.subject} </td>
                <td> {dt.priority} </td>
                <td> {dt.request_date} </td>
                <td> {dt.expected_completion_date} </td>
                <td>
                  <TimeRemaining
                    future={dt.expected_completion_date}
                    now={dt.request_date}
                    status={dt.request_progress}
                  />
                </td>
                <td> <Progress progress={dt.request_progress}/> </td>
                <td>
                  <button className="btn btn-success view" onClick={getDetails}>
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RequestTable;
