import { useState, useEffect } from "react";
import RequestTable from "./RequestTable";

const ViewRequests = () => {
  const contact_id = localStorage.getItem("contact_id");
  const [loadedRequests, setLoadedRequests] = useState([]);

  useEffect(() => {
    requests()
      .then((data) => {
        setLoadedRequests(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const requests = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/viewRequests/" + contact_id
    );
    if (response.status !== 200) {
      throw new Error("There was an error fetching requests :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return (
      <RequestTable loadedRequests={loadedRequests} />
  );
};

export default ViewRequests;
