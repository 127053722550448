import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import TicketDetailsPage from "./TicketDetailsPage";

const TicketDetails = () => {
  const location = useLocation();
  const [loadedRequestDetails, setLoadedRequestDetails] = useState([]);
  if (location.state !== undefined) {
    var request_id = location.state.response[0];
    localStorage.setItem("request_id", request_id);
  } else {
    var request_id = "";
  }
  useEffect(() => {
    getDetails()
      .then((data) => {
        setLoadedRequestDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const getDetails = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/requestDetails/" + request_id
    );
    if (response.status !== 200) {
      throw new Error("There was an error fetching tickets :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return <TicketDetailsPage data={loadedRequestDetails} id={request_id} />;
};

export default TicketDetails;
