import ReactDOM from "react-dom";
import PortalBody from "../components/layout/PortalBody";
import { BrowserRouter } from "react-router-dom";

const Login = () => {
  const loginClient = () => {
    const formLogin = document.getElementById("formLogin");
    formLogin.addEventListener("submit", (e) => {
      const formData = new FormData(formLogin);
      fetchContact(formData)
        .then((data) => {        
          if (data.length !== 0) {
            saveClientContactInStorage(data);
            data.map((dt) => {
              companyLogo(dt.client_id);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      e.preventDefault();
    });
  };

  const companyLogo = (client_id) => {
    fetchLogo(client_id)
      .then((data) => {
        if (data.length !== 0) {
          console.log(data);
          saveClientInStorage(data);
          ReactDOM.render(
            <BrowserRouter>
              <PortalBody imgUrl={data.url} alt={data.name} />
            </BrowserRouter>,
            document.getElementById("root")
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchContact = async (formDt) => {
    const url = "https://www.innovations.co.ke/portalapi/public/api/clientContactLogin";
    const options = {
      method: "POST",
      body: formDt,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error("There was an error fetching client :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  const fetchLogo = async (client_id) => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/viewClientUrl/" + client_id
    );
    if (response.status !== 200) {
      throw new Error(
        "There was an error fetching client logo url :" + response.status
      );
    }
    const data = await response.json();
    return data;
  };

  const saveClientContactInStorage = (data) => {
    data.map((dt) => {
      if (typeof Storage !== "undefined") {
        localStorage.setItem("client_id", dt.client_id);
        localStorage.setItem("contact_email", dt.email);
        localStorage.setItem("contact_name", dt.name);
        localStorage.setItem("contact_id", dt.client_contact_id);
      } else {
        console.log("could not save client data in local storage");
      }
    });
  };

  const saveClientInStorage = (data) => {
    if (typeof Storage !== "undefined") {
      localStorage.setItem("url", data.url);
      localStorage.setItem("client_name", data.name);
    } else {
      console.log("could not save client url and client name in local storage");
    }
  };

  return (
    <div className="card col-md-4 mx-auto" style={{ marginTop: "20%" }}>
      <div className="card-header text-center bg-white">
        <h1>
          <span className="text-danger fw-bolder">Login </span>
        </h1>
      </div>
      <div className="card-body">
        <form id="formLogin" className="d-grid gap-3">
          <div className="form-group" id="formLogin">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              autoComplete="off"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              autoComplete="off"
              id="password"
              name="password"
              required
            />
          </div>
          <button
            type="submit"
            id="submit"
            onClick={loginClient}
            className="btn btn-secondary mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
